/* eslint-disable consistent-return */
import React, { useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useTranslation } from 'src/i18n';
import useMediaQuery from '@mui/material/useMediaQuery';
import CommonTrans from 'components/Globals/CommonTrans';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import useNewTracking from 'components/Globals/Analytics';
import LinkButton from 'components/uiLibrary/LinkButton';
import Login from 'components/Globals/Accounts/Login';
import {
  COMPONENTS,
  GOOGLE_OLD_TRACKING_SERVICES,
  PAYWALL_SECTION_MAP,
  SECTIONS,
  SUB_COMPONENTS,
  ACTION_CALLBACK_STATUS,
} from 'components/Globals/Analytics/constants';
import Modal from 'components/uiLibrary/Modal';
import Image from 'components/uiLibrary/Image';
import Testimonials from 'components/Globals/Testimonials';
import Typography from 'components/uiLibrary/Typography';
import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import { SUBSCRIPTION_PRODUCT_IDS } from 'containers/Accounts/constants';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import { TRACK_EVENTS, PAYWALL_EVENT_IDS } from 'utils/tracking';
import useAppContext from 'utils/hooks/useAppContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import { usePaywallContext } from 'utils/hooks/usePaywallContext';
import usePageContext from 'utils/hooks/usePageContext';
import { useProfileAccesses, useUserSubscriptions } from 'utils/hooks/useAuthenticatedUser';

import { PAYWALL_TYPES, TP, URL_STATIC_PATHS, LOGIN_SOURCE } from 'constants/index';
import { ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';
import LogoIcon from 'public/images/ob-icon.svg';
import LoggedInPaywall from './LoggedInPaywall';
import classes from './PaywallModal.module.scss';

const CustomerLogos = dynamic(() => import('components/Globals/CustomerLogos'));

const profileTypes = ['profile', 'agency', 'organization'];

const PaywallModal = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { data: allSubscriptions } = useUserSubscriptions();

  const hasActiveSubscription = useMemo(
    () => allSubscriptions?.some(subscription => subscription?.status === 'active'),
    [allSubscriptions],
  );

  const { showPaywall, setShowPaywall } = usePaywallContext();
  const {
    data: { data: allProfilesAccesses },
    ownerProfile,
  } = useProfileAccesses();
  const { isLoggedIn, paywallType, setPaywallType } = useAppContext();
  const { isLoginDialog, setIsLoginDialog } = useDialogs();
  const { navigate, entity, edit } = usePageContext();
  const isMobile = useMediaQuery('(max-width:1024px)');
  const [slideDown, setSlideDown] = useState(!isLoggedIn);
  const isHardWall = paywallType === PAYWALL_TYPES.HARD_WALL;
  const { profileData } = useGetTargetProfile();
  const track = useNewTracking();
  const hasActiveProfile = profileData?.id;

  const hasEntityAccess = allProfilesAccesses?.some(profileAccess =>
    profileTypes.some(key => entity?.id && profileAccess?.[key]?.id === entity?.id),
  );

  const ownerProfileId = ownerProfile?.profile?.id;
  const ownerProfileSubscription = allSubscriptions?.find(
    sub =>
      sub.status === 'active' &&
      sub.subscription?.product?.id &&
      [SUBSCRIPTION_PRODUCT_IDS.PROFILE_MONTHLY, SUBSCRIPTION_PRODUCT_IDS.PROFILE_ANNUAL].includes(
        sub.subscription.product.id,
      ) &&
      sub.profile?.id === ownerProfileId,
  );

  const hasAnnualSubscription =
    ownerProfileSubscription?.subscription?.product?.id === SUBSCRIPTION_PRODUCT_IDS.PROFILE_ANNUAL;

  useEffect(() => {
    if (paywallType) {
      setShowPaywall(true);
      setSlideDown(false);
      if (isLoginDialog.source !== LOGIN_SOURCE.RETURNING_USER) {
        setIsLoginDialog({ ...isLoginDialog, source: paywallType || LOGIN_SOURCE.PAYWALL_SIDE_CLICK });
      }
    }
  }, [paywallType, setPaywallType, setShowPaywall, isLoginDialog]);

  useEffect(() => {
    if (edit || (isLoginDialog.source === LOGIN_SOURCE.RETURNING_USER && isLoggedIn)) {
      setShowPaywall(false);
      setSlideDown(true);
      setPaywallType('');
    }
  }, [edit, isLoggedIn, isLoginDialog.source, setPaywallType, setShowPaywall]);

  useEffect(() => {
    const resetBodyStyles = () => {
      if (document && document.body) {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
      }
    };

    if (typeof window !== 'undefined' && document && document.body) {
      if (!slideDown) {
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${scrollbarWidth}px`;
      } else {
        setTimeout(resetBodyStyles, 100);
      }
    }

    return resetBodyStyles;
  }, [showPaywall, slideDown]);

  useEffect(() => {
    if (!isLoggedIn && isHardWall) {
      setSlideDown(false);
    }
  }, [isHardWall, isLoggedIn]);

  useEffect(() => {
    if (showPaywall) {
      // NOTE: need to deprecate this event
      track.click(
        {
          name: TRACK_EVENTS.VIEW_PAYWALL,
          data: {
            actionType: paywallType,
          },
        },
        GOOGLE_OLD_TRACKING_SERVICES,
      );
      track.actionCallback({
        section: paywallType ? PAYWALL_SECTION_MAP[paywallType] : SECTIONS.PAYWALL,
        component: COMPONENTS.MODAL,
        subComponent: isLoggedIn ? SUB_COMPONENTS.PAYWALL_MODAL_LOGGED_IN : SUB_COMPONENTS.PAYWALL_MODAL_NON_LOGGED_IN,
        meta: {
          step: paywallType,
          actionStatus: ACTION_CALLBACK_STATUS.SUCCESS,
          isOpen: true,
        },
      });
    }
  }, [showPaywall, track, paywallType, isLoggedIn]);

  const togglePaywall = () => {
    const paywallContent = document.getElementById('paywallContent');
    paywallContent.classList.toggle('paywall__main__show');
    if (paywallType) {
      setPaywallType('');
    }
  };

  const ignorePaywallHandler = () => {
    togglePaywall();
    setSlideDown(prev => !prev);

    if (!slideDown) {
      if (paywallType) {
        track.closePaywall(paywallType);
        track.click(
          {
            name: TRACK_EVENTS.CLICK,
            data: {
              id: PAYWALL_EVENT_IDS.PAYWALL_IGNORE,
              actionType: paywallType,
            },
          },
          GOOGLE_OLD_TRACKING_SERVICES,
        );
      } else {
        track.click({
          section: SECTIONS.PAYWALL,
          component: COMPONENTS.EXPANDABLE_CARD,
          subComponent: SUB_COMPONENTS.SHOW_HIDE_TOGGLE,
          meta: {
            actionType: paywallType,
            isOpen: slideDown,
          },
        });
      }
    }

    if (slideDown) {
      track.click({
        section: SECTIONS.PAYWALL,
        component: COMPONENTS.EXPANDABLE_CARD,
        subComponent: SUB_COMPONENTS.SHOW_HIDE_TOGGLE,
        meta: {
          actionType: paywallType,
          isOpen: slideDown,
        },
      });
    }
  };

  const nonLoggedInPaywall = (
    <>
      {slideDown && (
        <div
          className={classnames(classes.paywallModalHeader, {
            [classes.paywallModalHeader__hideContent]: slideDown,
          })}
          onClick={e => {
            e.stopPropagation();
            ignorePaywallHandler();
          }}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <div className={classes.paywallModalHeader__wrapper}>
            {!isMobile && (
              <div className={classes.paywallModalHeader__leftSection}>
                <CommonTrans
                  i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
                  ns="NS_APP_GLOBALS"
                  components={{
                    ul: <ul className={classes.listWorks} />,
                    li: <li />,
                  }}
                />
                {!isHardWall && (
                  <div
                    className={classnames(classes.buttonArrowDown, {
                      [classes.btnCta__close]: slideDown,
                    })}
                    onClick={e => {
                      e.stopPropagation();
                      ignorePaywallHandler();
                    }}
                    role="button"
                    tabIndex={0}
                    aria-label="Close Paywall Modal"
                    onKeyDown={() => {}}
                  >
                    <SpriteIcon
                      className={classnames(classes.btnCta)}
                      icon={isLoggedIn ? 'close' : 'chevron_right'}
                      size={16}
                    />
                  </div>
                )}
              </div>
            )}
            <div className={classes.paywallModalHeader__rightSection}>
              {!isLoggedIn && (
                <div className={classes.paywallModalHeader__rightSection_title}>
                  <Image
                    src={LogoIcon}
                    alt="Operabase Home"
                    lazy={false}
                    className={classes.logoIcon}
                    disableCloudinary
                    disableNextImage
                  />
                  <Typography size={isMobile ? 12 : 16} weight="bold">
                    {t(`${TP}.OPERABASE_CONTINUE_LOGIN`)} {t(`${TP}.FREE_TO_USE`)}
                  </Typography>
                </div>
              )}
              <div className={classes.paywallModalHeader__rightSection_actions}>
                {!isLoggedIn && (
                  <LinkButton
                    styles={{ root: classes.paywallModalHeader__rightSection_actions_titleLogin }}
                    variant="text"
                    onClick={e => {
                      e.stopPropagation();
                      ignorePaywallHandler();
                      setIsLoginDialog({ ...isLoginDialog, source: paywallType || LOGIN_SOURCE.PAYWALL_SIDE_CLICK });
                    }}
                  >
                    {t(`${TP}.LOGIN_REGISTER`)}
                    <SpriteIcon
                      icon="chevron_right"
                      size={16}
                      className={classnames(
                        classes.paywallModalHeader__rightSection_actions_titleLogin_arrowRightIconNone,
                      )}
                    />
                  </LinkButton>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        id="paywallContent"
        className={classnames(classes.paywall__main, { [classes.paywall__main__show]: !slideDown })}
      >
        {!isHardWall && (
          <div className={classnames(classes.paywall__main__header)}>
            <LinkButton
              variant="text"
              leftIcon={<SpriteIcon icon="close" size={18} />}
              onClick={ignorePaywallHandler}
            />
          </div>
        )}
        <div
          className={classnames(classes.paywall__modal__wrapper, {
            [classes.paywall__modal__wrapper__hardWall]: isLoggedIn && hasActiveProfile,
            [classes.paywall__modal__ignore]: slideDown,
          })}
          id="paywall_active"
        >
          <>
            <div className={classes.paywall__modal__content}>
              <Login step={isLoginDialog.step} isPaywall isModal={false} />
            </div>
          </>
        </div>
        <div className={classes.footer}>
          {isMobile && (
            <Typography variant="p" className={classes.title} size={12} italic>
              {t(`${TP}.FN_LOGIN_POPUP_BOTTOM_SECTION_TITLE`)}
            </Typography>
          )}

          <div className={classes.footer__customerLogo}>
            {isMobile && (
              <CustomerLogos
                showTitle={false}
                rowHeight={isMobile ? 35 : 40}
                count={isMobile ? 4 : 16}
                onClickViewAll={() => !isHardWall && setSlideDown(true)}
                className={classes.footer__fluid}
                allowShowAll={false}
              />
            )}
          </div>
          {!isMobile && (
            <div className={classnames(classes.testimonials, classes.testimonials__title)}>
              <Typography>
                {t(`${TP}.TRUSTED_BY_PERFORMING_ARTS_INDUSTRY`)} -{' '}
                <LinkButton
                  variant="text"
                  {...navigate.getLinkProps({ baseRoute: ROUTE_RESERVED_KEYWORDS.pro, path: URL_STATIC_PATHS.COMPANIES })}
                >
                  {t(`${TP}.VIEW_COMPLETE_LIST_CTA`)}
                </LinkButton>
              </Typography>
            </div>
          )}
          <div className={classes.testimonials}>
            {!isMobile && (
              <div className={classnames(classes.testimonials__list__wrapper, classes.leftSection)}>
                <CustomerLogos
                  showTitle={false}
                  rowHeight={40}
                  count={3}
                  onClickViewAll={() => !isHardWall && setSlideDown(true)}
                  className={classes.footer__fluid}
                  allowShowAll={false}
                />
              </div>
            )}
            <div className={classes.rightSection}>
              <HorizontalScrollIndicators
                styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
                triggerSize={0}
                isArrowsEnabled
              >
                <Testimonials hideTitle inLine limit={isMobile ? 6 : 12} className={classes.testimonials__list} />
              </HorizontalScrollIndicators>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  if (edit || isLoginDialog.source === LOGIN_SOURCE.RETURNING_USER) {
    return null;
  }

  const hideStickyHeader = (slideDown && hasEntityAccess) || (slideDown && hasActiveSubscription);
  return (
    <Modal
      styles={{
        drawerRoot: classnames(classes.drawerRoot, {
          [classes.removeDrawerRootcss]: slideDown,
        }),
        modalContainer: classes.modalContainer,
        backdrop: classnames({
          [classes.disabled]: isHardWall,
          [classes.backdrop]: slideDown && !isHardWall,
        }),
        content: classes.drawerContent,
        drawerPaper: classnames(classes.drawerPaper, {
          [classes.withSlideStyle]: slideDown,
          [classes.withSlideStyle__noProfile]: hideStickyHeader,
        }),
      }}
      className={classes.paywall__modal}
      isOpen={!isLoggedIn || showPaywall}
      isDrawer
      disableEnforceFocus
      blockBackgroundScroll={isMobile}
    >
      <div
        className={classnames(classes.loggedInPaywallWrapper, {
          [classes.loggedInPaywallWrapper__nonLoggedIn]: !isLoggedIn,
        })}
      >
        {isLoggedIn ? (
          <>
            <div
              className={classnames(classes.paywallModalHeader, {
                [classes.paywallModalHeader__hideContent]: slideDown,
                [classes.paywallModalHeader__hideContent_mobile]: !slideDown && isMobile,
                [classes.paywallModalHeader__hideContent__hasProfileAccess]: hideStickyHeader,
              })}
              onClick={e => {
                e.stopPropagation();
                ignorePaywallHandler();
              }}
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
            >
              <div
                className={classnames(
                  classes.paywallModalHeader__wrapper,
                  classes.paywallModalHeader__wrapper__loggedIn,
                )}
              >
                <div className={classes.paywallModalHeader__leftSection}>
                  <div className={classes.paywallModalHeader__leftSection_logoTitle}>
                    <Image
                      src={LogoIcon}
                      alt="Operabase Home"
                      lazy={false}
                      className={classes.logoIcon}
                      disableCloudinary
                      disableNextImage
                    />
                    {hasActiveSubscription ? (
                      <Typography size={isMobile ? 12 : 16}>
                        {hasAnnualSubscription
                          ? t(`${TP}.LIMITED_ACCESS_TITLE_HAS_SUB`)
                          : t(`${TP}.LIMITED_ACCESS_TITLE_NO_ACCESS`)}
                      </Typography>
                    ) : (
                      <Typography size={isMobile ? 12 : 16}>
                        {t(`${TP}.FREE_PLAN_TITLE_CURRENTLY`)}{' '}
                        <Typography size={isMobile ? 12 : 16} color="orange">
                          ({t(`${TP}.FREE_PLAN_OPTIONS`)})
                        </Typography>
                      </Typography>
                    )}
                  </div>
                </div>
                {slideDown && (
                  <div className={classes.paywallModalHeader__rightSection}>
                    {!isMobile && <Typography size={14}>{t(`${TP}.PAYWALL_COLLAPSED_TITLE`)} |</Typography>}
                    <div className={classes.paywallModalHeader__rightSection_actions}>
                      <Typography className={classes.paywallModalHeader__rightSection_actionTitle} weight="bold">
                        {!isMobile && `${t(`${TP}.AS_SUBSCRIPTIONS_UPGRADE`)} |`} {t(`${TP}.SEE_PLAN`)}
                      </Typography>
                      <div
                        className={classnames(classes.buttonArrowDown, {
                          [classes.btnCta__close]: slideDown,
                        })}
                        onClick={e => {
                          e.stopPropagation();
                          ignorePaywallHandler();
                        }}
                        role="button"
                        tabIndex={0}
                        aria-label="Close Paywall Modal"
                        onKeyDown={() => {}}
                      >
                        <SpriteIcon className={classnames(classes.btnCta)} icon="chevron_right" size={16} />
                      </div>
                    </div>
                  </div>
                )}
                {!slideDown && (
                  <div className={classes.paywallModalHeader__rightSection}>
                    {!isMobile && (
                      <LinkButton
                        variant="text"
                        rightIcon={<SpriteIcon size={16} icon="chevron_right" />}
                        onClick={ignorePaywallHandler}
                        styles={{ root: classnames(classes.closeBtn) }}
                        preventDefault
                        stopPropagation
                        disableHover
                        disableUnderline
                      >
                        {!isMobile && (
                          <Typography size="12" weight="medium" color="link">
                            {hasActiveSubscription ? t(`${TP}.m_CONTINUE`) : t(`${TP}.CONT_FOR_FREE_BTN`)}
                          </Typography>
                        )}
                      </LinkButton>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div
              id="paywallContent"
              className={classnames(classes.paywall__main, { [classes.paywall__main__show]: !slideDown })}
            >
              <LoggedInPaywall
                onCloseHandler={ignorePaywallHandler}
                hasActiveSubscription={hasActiveSubscription}
                hasAnnualSubscription={hasAnnualSubscription}
              />
            </div>
          </>
        ) : (
          nonLoggedInPaywall
        )}
      </div>
    </Modal>
  );
};

export default PaywallModal;
